<div fxLayout="row" fxLayoutAlign="center center">
  <button mat-icon-button (click)="close()"><mat-icon>keyboard_arrow_down</mat-icon></button>
</div>
<mat-selection-list [multiple]="false">
  <mat-list-option *ngFor="let device of data.devices" >
    <h4 mat-line style="margin-bottom: 0px; margin-top: 10px;">{{device.name}}</h4>
    <h5 mat-line>Ultima conexión: {{device.data.lastUpdate | date : 'd MMM y, HH:mm'}} hrs.</h5>
    <mat-divider></mat-divider>
  </mat-list-option>
</mat-selection-list>

<div class="sidenav-container">
  <mat-toolbar color="accent" class="mat-elevation-z3">
    <mat-icon style="margin-right: 5px;">filter_alt</mat-icon>
    <span>Filtros de Busqueda</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="sidenavService.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-progress-bar *ngIf="searching" mode="indeterminate" color="accent"></mat-progress-bar>

  <div style="margin: 25px">

    <mat-vertical-stepper [linear]="true" [selectedIndex]="selectedIndex">
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Seleccione dispositivos WES</ng-template>
          <mat-form-field appearance="outline">
            <mat-label>Wes devices</mat-label>
            <mat-select [formControl]="this.firstFormGroup.get('nodesFormControl')" multiple >
              <mat-select-trigger>
                {{this.firstFormGroup.get('nodesFormControl').value?.length > 0 ? this.firstFormGroup.get('nodesFormControl').value[0].name : ''}}
              </mat-select-trigger>
              <mat-option [value]="0"  #allSelected (click)="toggleAllSelection()">Seleccionar todos</mat-option>
              <mat-option *ngFor="let node of allowedNodes" [value]="node" (click)="tosslePerOne(allSelected.viewValue)">{{node.name}}</mat-option>
            </mat-select>
            <span *ngIf="this.firstFormGroup.get('nodesFormControl').value?.length > 1" class="example-additional-selection">
        (+{{ this.allSelected.selected ? this.firstFormGroup.get('nodesFormControl').value.length - 2 : this.firstFormGroup.get('nodesFormControl').value.length - 1}} {{this.firstFormGroup.get('nodesFormControl').value?.length === 1 ? 'dispositivo' : 'dispositivos'}})
      </span>
          <span *ngIf="this.firstFormGroup.get('nodesFormControl').hasError('required')" class="example-additional-selection" style="color: red">
            Campo <strong>requerido</strong>
          </span>
          </mat-form-field>
          <div>
            <button mat-raised-button color="accent" matStepperNext>Continuar</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Indique tipo de Busqueda</ng-template>

          <section style="margin-top: 10px;margin-bottom: 20px;">
            <mat-radio-group formControlName="searchType">
              <mat-radio-button value="1" (click)="resetDates()">Por rango de fecha</mat-radio-button>
              <mat-radio-button value="2" (click)="setDatesAsToDate()">Hoy</mat-radio-button>
            </mat-radio-group>
          </section>

          <div style="font-size: 12px; margin-bottom: 20px;">
          <mat-form-field color="primary" appearance="outline">
            <mat-label>Busqueda Desde</mat-label>
            <input matInput [matDatepicker]="picker1" [formControl]="secondFormGroup.get('date_from')" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 disabled="{{secondFormGroup.value.searchType != '1'}}" touchUi="true"></mat-datepicker>
            <mat-error *ngIf="secondFormGroup.get('date_from').hasError('required')">
              Fecha <strong>requerida</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Hasta</mat-label>
            <input matInput [matDatepicker]="picker2" [formControl]="secondFormGroup.get('date_to')" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary" disabled="{{secondFormGroup.value.searchType != '1'}}" touchUi="true"></mat-datepicker>
            <mat-error *ngIf="secondFormGroup.get('date_to').hasError('required')">
              Fecha <strong>requerida</strong>
            </mat-error>
          </mat-form-field>
          </div>
          <div>
            <button mat-raised-button matStepperPrevious style="margin-right: 5px">Atrás</button>
            <button mat-raised-button color="accent" (click)="search()">Filtrar</button>
          </div>
        </form>
      </mat-step>

    </mat-vertical-stepper>
  </div>

</div>




import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-wes-onoff-dialog',
  templateUrl: './wes-onoff-dialog.component.html',
  styleUrls: ['./wes-onoff-dialog.component.scss']
})
export class WesOnoffDialogComponent implements OnInit {

  public nodeName: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

  }

}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SidenavService} from "../../services/sidenav/sidenav.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatOption} from "@angular/material/core";

@Component({
  selector: 'wes-sidenav-search-content',
  templateUrl: './wes-sidenav-search-content.component.html',
  styleUrls: ['./wes-sidenav-search-content.component.scss']
})
export class WesSidenavSearchContentComponent implements OnInit {

  @Input('nodes') allowedNodes: Array<any>;
  @Input('activeNode') activeNode: Array<any>;
  @Input('activeFilter') activeFilter: any;
  @Output() searchEvent = new EventEmitter<any>();
  public searching: boolean = false;
  public selectedIndex: number = 0;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  @ViewChild('allSelected') private allSelected: MatOption;

  constructor(private _formBuilder: FormBuilder, public sidenavService: SidenavService) {}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      nodesFormControl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
      searchType: ['', Validators.required],
      date_from: ['', Validators.required],
      date_to: ['', Validators.required]
    });
    this.firstFormGroup.get('nodesFormControl').setValue(this.activeNode);
    if(this.activeFilter) {
      this.selectedIndex = 1;
      this.secondFormGroup.get('date_to').setValue(this.activeFilter.date_to);
      this.secondFormGroup.get('date_from').setValue(this.activeFilter.date_from);
      this.secondFormGroup.get('searchType').setValue(this.activeFilter.searchType);
    }
  }

  public setDatesAsToDate() {
    this.secondFormGroup.get('date_from').setValue(new Date());
    this.secondFormGroup.get('date_to').setValue(new Date());
  }

  public resetDates() {
    this.secondFormGroup.get('date_from').reset();
    this.secondFormGroup.get('date_to').reset();
  }

  public search() {

    if (this.secondFormGroup.get('date_from').invalid && this.secondFormGroup.get('date_to').invalid) {
      this.secondFormGroup.get('date_to').markAsTouched();
      this.secondFormGroup.get('date_from').markAsTouched();
      return
    } else if (this.secondFormGroup.get('date_from').invalid) {
      this.secondFormGroup.get('date_from').markAsTouched();
      return
    } else if (this.secondFormGroup.get('date_to').invalid) {
      this.secondFormGroup.get('date_to').markAsTouched();
      return
    }

    let filter: any = {
      nodes: this.firstFormGroup.get('nodesFormControl').value,
      date_to: this.secondFormGroup.get('date_to').value,
      date_from: this.secondFormGroup.get('date_from').value,
      searchType: this.secondFormGroup.get('searchType').value
    };

    if (this.allSelected.selected) {
      let indexToDelete = 0;
      filter.nodes.forEach((node, index) => {
        if(node.nodeId === undefined) {
          indexToDelete = index;
        }
      });
      filter.nodes.splice(indexToDelete, 1);
    }

    this.searching = true;
    setTimeout(() => {
      this.searching = false;
      this.searchEvent.emit(filter);
      this.sidenavService.close();
    }, 500);
  }

  tosslePerOne(all){
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if(this.firstFormGroup.controls.nodesFormControl.value.length == this.allowedNodes.length)
      this.allSelected.select();

  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.firstFormGroup.controls.nodesFormControl.patchValue([...this.allowedNodes.map(item => item), 0]);
    } else {
      this.firstFormGroup.controls.nodesFormControl.patchValue([]);
    }
  }
}

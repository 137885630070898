<div fxLayout="column" fxLayoutAlign="center center">
  <mat-progress-bar *ngIf="this.loading" mode="indeterminate"></mat-progress-bar>

  <mat-card class="example-card mat-elevation-z0" >
    <mat-card-header>
      <mat-card-title><img src="/assets/logo.png" style="width: 180px; height: 45px;"/></mat-card-title>
    </mat-card-header>
    <mat-card-content style="margin-top: 20px;" *ngIf="!change_password">
      <table class="example-full-width" cellspacing="0">
        <tr>
          <td>
            <mat-form-field appearance="legacy" >
              <mat-label>E-mail</mat-label>
              <input matInput placeholder="username@mail.com" name="username" [formControl]="username" [errorStateMatcher]="matcher">
              <button mat-button *ngIf="username.value" matSuffix mat-icon-button aria-label="Clear" (click)="username.setValue('')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="username.hasError('required')">
                Campo <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field appearance="legacy" >
              <mat-label>Password</mat-label>
              <input matInput placeholder="******" type="password" name="password" [formControl]="password" [errorStateMatcher]="matcher">
              <button mat-button *ngIf="password.value" matSuffix mat-icon-button aria-label="Clear" (click)="password.setValue('')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="password.hasError('required')">Campo <strong>requerido</strong>
              </mat-error>
              <mat-hint *ngIf="error.login.visible">{{error.login.message}}</mat-hint>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td><button style="margin-top: 20px" mat-raised-button disabled="{{this.loading}}" (click)="login()" color="primary">Entrar</button></td>
        </tr>
      </table>
    </mat-card-content>

    <mat-card-content style="margin-top: 20px;" *ngIf="change_password">
      <table class="example-full-width" cellspacing="0">
        <tr>
          <td><h5>Enviaremos una nueva contraseña <br> al correo:</h5></td>
        </tr>
        <tr>
          <td>
            <mat-form-field appearance="legacy" >
              <mat-label>E-mail</mat-label>
              <input matInput placeholder="username@mail.com" name="username" [formControl]="change_password_email" [errorStateMatcher]="matcher">
              <button mat-button *ngIf="change_password_email.value" matSuffix mat-icon-button aria-label="Clear" (click)="change_password_email.setValue('')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="change_password_email.hasError('required')">
                Campo <strong>requerido</strong>
              </mat-error>
              <mat-hint *ngIf="error.change.visible">{{error.change.message}}</mat-hint>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td><button style="margin-top: 20px" mat-raised-button disabled="{{this.loading}}" (click)="forgotPassword()" color="primary">Enviar</button></td>
        </tr>
      </table>
    </mat-card-content>

  </mat-card>
  <a *ngIf="change_password" style="margin-top: 10px;"mat-flat-button (click)="changeMode()"><small>Volver</small></a>
  <a *ngIf="!change_password" style="margin-top: 10px;"mat-flat-button (click)="changeMode()"><small>Olvidé mi contraseña</small></a>
</div>


import {Component, Input, OnInit} from '@angular/core';
import {ErrorStateMatcher} from "@angular/material/core";
import {FormControl, FormGroupDirective, NgForm} from "@angular/forms";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-wes-devices',
  templateUrl: './wes-devices.component.html',
  styleUrls: ['./wes-devices.component.scss']
})
export class WesDevicesComponent implements OnInit {
  panelOpenState = false;
  matcher = new MyErrorStateMatcher();
  @Input() public loading: boolean;
  public devices: any;

  public set_devices(devices: any) {
    this.devices = devices;
  }

  constructor() { }

  ngOnInit(): void {
  }

  have_alert(device) {
    /*
    if(device.data.stream.split("@").length > 9 ) {
      device.data.stream.split("@")
      return true;
    }

     */
    return false;
  }

  lastUpdate(item) {
    let dateSent = new Date(item.data.lastUpdate * 1);
    let currentDate = new Date();
    let difference = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) -
      Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) / (1000 * 60 * 60 * 24))
    return difference;
  }

}

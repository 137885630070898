import { Component, OnInit } from '@angular/core';
import {SidenavService} from "../../services/sidenav/sidenav.service";

@Component({
  selector: 'wes-auditories',
  templateUrl: './wes-auditories.component.html',
  styleUrls: ['./wes-auditories.component.scss']
})
export class WesAuditoriesComponent implements OnInit {

  constructor(public sidenavService: SidenavService) { }
  ngOnInit(): void {
  }

}

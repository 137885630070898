import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserService} from "../../services/user/user.service";
import {Router} from "@angular/router";
import { AuthServiceService } from '../../services/auth-service/auth-service.service';

@Component({
  selector: 'wes-navigation-bar',
  templateUrl: './wes-navigation-bar.component.html',
  styleUrls: ['./wes-navigation-bar.component.scss']
})
export class WesNavigationBarComponent implements OnInit {

  public user_description: string;
  public company_name: string;
  public company_image: string;
  @Output() toggleSidenav = new EventEmitter<void>();

  constructor(private router: Router, private userService: UserService, private autService: AuthServiceService) { }

  ngOnInit(): void {
    console.log(this.autService.getAuth());
    let session: string = this.userService.retrieve_user_id();
    this.userService.fetch_session_information(session).then( value => {
      this.user_description = `${value.name} ${value.lastName}`
      this.company_name = value.companyName;
      this.company_image = value.companyImage;
    });
  }

  viewProfile() {
    this.router.navigateByUrl("/wes/profile");
  }

  closeSession() {
    localStorage.clear();
    this.autService.logout();
    console.log(this.autService.getAuth());
    this.router.navigateByUrl("/login");
  }

}

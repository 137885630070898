import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  constructor(private httpClient: HttpClient) { }
  private host: string = "https://api-rest.wes.cl/wes/api/wes-mc-api/v1/";

  sendOnOff(command: string, nodeId: string): Observable<any> {
    let url: string = this.host + `sendcommand`;
    let response: Observable<any> = this.httpClient.post(url, {nodeId: nodeId, command: command});
    return response;
  }

  sendOnOffSync(command: string, nodeId: string, node: any): Promise<any> {
    let url: string = this.host + `sendcommand?id=${nodeId}&command=${command}`;
    return new Promise((resolve, reject) => {
      const apiURL = url;
      this.httpClient
        .get<any[]>(apiURL)
        .toPromise()
        .then((res: any) => {
            node.waitingCommand = true;
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  fetch_node_data(nodeId: string): Observable<any> {
    let url: string = this.host + `nodes/${ nodeId }/information`;
    return <Observable<any>> this.httpClient.get(url);
  }

  fetch_multiple_node_data(nodes: Array<any>): Observable<any[]> {
    let url: string = this.host + `nodes/information`;
    nodes.forEach(function (node, index) {
      if(index == 0) {
        url = `${url}?id=${node.nodeId}`;
      } else {
        url = `${url}&id=${node.nodeId}`;
      }
    });
    return <Observable<any[]>> this.httpClient.get(url);
  }

  fetch_kpi(nodeId: string, from: string, to: string): Observable<any[]> {
    let url: string = this.host + `nodes/${ nodeId }/kpi?start=${from}&end=${to}`;
    return <Observable<any[]>> this.httpClient.get(url);
  }

  fetch_kpi_multiple(array: Array<any>, from: string, to: string): Observable<any[]> {
    let url: string = `${this.host}nodes/kpi`;
    array.forEach(function (object, index) {
      if(index == 0) {
        url = `${url}?id=${object.nodeId}`;
      } else {
        url = `${url}&id=${object.nodeId}`;
      }
    });
    url  = `${url}&start=${from}&end=${to}`;
    return <Observable<any[]>> this.httpClient.get(url);
  }

  fetch_alerts_multiple(array: Array<string>, companyId: string, from: string, to: string): Observable<any[]> {
    let url: string = `${this.host}nodes/alerts`;
    array.forEach(function (nodeId, index) {
      if(index == 0) {
        url = `${url}?id=${nodeId}`;
      } else {
        url = `${url}&id=${nodeId}`;
      }
    });
    url  = `${url}&companyId=${companyId}&start=${from}&end=${to}`;
    return <Observable<any[]>> this.httpClient.get(url);
  }

}

import {SidenavService} from "../../services/sidenav/sidenav.service";
import {Component, OnInit, OnDestroy} from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_data from 'highcharts/modules/data';
import {DatePipe, registerLocaleData} from '@angular/common';
import es from '@angular/common/locales/es';
import {UserService} from "../../services/user/user.service";
import {DashboardService} from "../../services/dashboard/dashboard.service";
import {NodeService} from "../../services/node/node.service";
import {Constants} from "../../../../constants";
import { OwlOptions } from 'ngx-owl-carousel-o';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {WesDevicesSheetComponent} from "../wes-devices-sheet/wes-devices-sheet.component";
import * as moment from 'moment-timezone';
import {CommandService} from "../../services/command/command.service";
import {timer, Subject} from 'rxjs';
import {retry, share, switchMap, takeUntil} from 'rxjs/operators';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
HC_data(Highcharts);

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'wes-control-panel',
  templateUrl: './wes-control-panel.component.html',
  styleUrls: ['./wes-control-panel.component.scss']
})
export class WesControlPanelComponent implements OnInit {

  aux: string;
  auxDate: any = {
    from: null,
    to: null
  };


  stopPolling = new Subject();
  centered = false;
  disabled = false;
  unbounded = false;
  radius: number;
  color: string;

  public connectedDevices: Array<any> = [];
  public disconnectedDevices: Array<any> = [];
  public globalDates: any = {
    from: "",
    to: "",
    fromFormat : "",
    toFormat: "",
  };

  public searchFilterParameters: any = null;
  public finalDate: Date;

  public customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  public devices: Array<any>;
  public loading: any = {
    devices: true,
    kpi: true,
    charts_m3: true,
    charts_lt: true
  };

  public kpi: any = {
    consumption: 0,
    efficiency: 0,
    savingM3: 0,
    savingAmount: 0,
    expenses: 0
  }

  public user_data: any = {allowedNodes: []};
  public nodeAlerts = [];
  public alertBadge;

  constructor(
    private datePipe: DatePipe,
    private dashboardService: DashboardService,
    private userService: UserService,
    private nodeService: NodeService,
    private commandService: CommandService,
    public sidenavService: SidenavService,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    private autService: AuthServiceService
  ) {

    //moment.tz.setDefault('Chile/Continental');
    moment.tz.guess(true);

    Highcharts.setOptions({
      lang: {
        numericSymbols: null,
        thousandsSep: ".",
        decimalPoint: ",",
        months: [
            'Enero', 'Febrero', 'Marzo', 'Abril',
            'Mayo', 'Junio', 'Julio', 'Agosto',
            'Sepriembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        weekdays: [
          'Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves','Viernes', 'Sabado'
        ],
        shortMonths: [
          'Ene.', 'Febr.', 'Mzo.', 'Abr.',
          'May.', 'Jun.', 'Jul.', 'Agt.',
          'Sept.', 'Oct.', 'Nov.', 'Dic.'
        ]
    
      }
    });
  }

  ngOnInit(): void {
    registerLocaleData( es );
    this.startComponent();
  }

  public searchFilter(searchFilter: any) {
    this.searchFilterParameters = searchFilter;
    this.auxDate.from=null;
    this.auxDate.to=null;

    if(searchFilter.nodes.length > 1) {
      this.searchMultipleNodes(searchFilter.nodes, searchFilter.date_from, searchFilter.date_to)
    } else {
      this.searchSingleNode(searchFilter.nodes[0], searchFilter.searchType, searchFilter.date_from, searchFilter.date_to);
    }
  }

  createReport() {
    let fromDate = this.datePipe.transform(this.globalDates.fromFormat,'ddMMyyyy');
    let toDate = this.datePipe.transform(this.globalDates.toFormat,'ddMMyyyy');
    this.dashboardService.retrieveReport(this.devices, fromDate, toDate);
  }
  
  createReportPdf() {
    let fromDate = this.datePipe.transform(this.globalDates.fromFormat,'ddMMyyyy');
    let toDate = this.datePipe.transform(this.globalDates.toFormat,'ddMMyyyy');
    this.dashboardService.retrieveReportPdf(this.devices, fromDate, toDate);
  }

  private searchMultipleNodes(nodes: Array<any>, from: Date, to: Date) {
    this.devices = nodes;
    this.globalDates.from = this.datePipe.transform(from,'d MMM y');
    this.globalDates.to = this.datePipe.transform(to,'d MMM y');

    this.globalDates.fromFormat = from;
    this.globalDates.toFormat = to;

    this.showM3xHrBetweenDatesAndMultiplesNodes(nodes, from, to);
    this.setKpiAndAlertsByMultiplesNodes(nodes, from, to);
  }

  private searchSingleNode(node: any, searchType: string, from: Date, to: Date) {
    this.globalDates.from = this.datePipe.transform(from,'d MMM y');
    this.globalDates.to = this.datePipe.transform(to,'d MMM y');

    this.globalDates.fromFormat = from;
    this.globalDates.toFormat = to;

    this.devices = [node];
    if(searchType === '1') {
      this.showM3xHrBetweenDates(node.nodeId, from, to);
      this.showRealtimeLtxMin(node.nodeId);
    } else {
      this.showRealtimeLtxMin(node.nodeId);
      this.showRealtimeM3xHr(node.nodeId, from);
    }
    this.setKpiAndAlerts(node.nodeId, from, to);
  }

  private startComponent() {
    this.loading.devices = true;
    let session: string = this.userService.retrieve_user_id();
    this.userService.fetch_session_information(session).then((data: any) => {
      this.user_data = data;
      this.nodeService.fetch_multiple_node_data(this.user_data.allowedNodes).subscribe((infos) => {
        this.commandService.fetch_pending_onoff_commands(this.user_data.allowedNodes).subscribe((commands) => {
          infos.forEach(infoNode => {
            let node = this.user_data.allowedNodes.filter((node) => { return node.nodeId == infoNode.nodeId })[0];
            node.data = node;
            node.data.lastUpdate = infoNode.lastUpdate;
            node.data.measureUpdate = infoNode.measureUpdate;
            let nodeCommand = commands.filter((commandNode) => { return commandNode.nodeId == node.nodeId });
            if (nodeCommand.length > 0) {
              node.waitingCommand = true;
              node.data.wesStatus = nodeCommand[0].commandName;
              node.pendingCommand = nodeCommand[0].commandName;
            } else {
              node.data.wesStatus = infoNode.wesStatus;
              node.waitingCommand = false;
            }
          });
          this.setDevicesByStatus(this.user_data.allowedNodes);
          let currentDate = new Date();
          this.devices = this.user_data.allowedNodes[0];
          this.searchSingleNode(this.user_data.allowedNodes[0], "2", currentDate, currentDate);
          this.loading.devices = false;
          this.pooling();
        });
      });
    });
  }

  private pooling() {
    timer(0, 15000).pipe(
      switchMap(() => this.nodeService.fetch_multiple_node_data(this.user_data.allowedNodes)),
      retry(),
      share(),
      takeUntil(this.autService.getAuth())
    ).subscribe(
      (infos: Array<any>) => {
        this.commandService.fetch_pending_onoff_commands(this.user_data.allowedNodes).subscribe((commands) => {
          infos.forEach(infoNode => {
            
            let node = this.user_data.allowedNodes.filter((node) => { return node.nodeId == infoNode.nodeId })[0];
            let nodeCommand = commands.filter((commandNode) => { return commandNode.nodeId == node.nodeId });
            
            node.data = node;
            node.data.lastUpdate = infoNode.lastUpdate;
            node.data.measureUpdate = infoNode.measureUpdate;

            if (nodeCommand.length > 0) {
              node.waitingCommand = true;
              node.data.wesStatus = nodeCommand[0].commandName;
              node.pendingCommand = nodeCommand[0].commandName;
            } else {
              node.data.wesStatus = infoNode.wesStatus;
              node.waitingCommand = false;
            }
          });
        });
        this.setDevicesByStatus(this.user_data.allowedNodes)
      }
    );
  }

  private setDevicesByStatus(devices: Array<any>) {
    this.disconnectedDevices = [];
    this.connectedDevices = [];
    devices.forEach((node) => {
      moment.locale('es')
      let differenceText = moment(new Date(node.data.measureUpdate * 1)).fromNow();
      node.data.differenceText = differenceText;
      node.data.differenceUpdateHours = moment(new Date(node.data.measureUpdate * 1)).diff(moment(), 'h') * -1;
      
      
      node.data.differenceConnectionText = moment(new Date(node.data.lastUpdate * 1)).fromNow();

      console.log(new Date(node.data.lastUpdate * 1));
      console.log(moment.utc());


      if(moment(new Date(node.data.lastUpdate * 1)).diff(moment(), 'h') * -1 >= 2) {
        this.disconnectedDevices.push(node);
      } else {
        this.connectedDevices.push(node);
      }
    });
  }

  showRealtimeM3xHr(nodeId: string, from: Date) {
    this.loading.charts_m3 = true;
    let chart = JSON.parse(JSON.stringify(Constants.base_chart));
    let date = this.datePipe.transform(from,'ddMMyyyy');
    this.retrieve_kpi(nodeId, date, date);

    chart.data = {
      csvURL: this.dashboardService.fetch_realtime_m3_hr(nodeId),
      enablePolling: this.autService.getAuth().observers.length > 0,
      dataRefreshRate: parseInt("10", 10)
    };

    /*
    this.dashboardService.retrieveTimestampEpoch().subscribe(value => {

    });
    */

    chart.yAxis = {
      gridLineDashStyle: 'dot',
      //softMax: 50,
      min: 0,
      title: {
        enabled: false,
        text: 'm3/hr'
      }
    };

    
    chart.time =  {
      timezoneOffset: -from.getTimezoneOffset()
    }
  
    /*
    chart.xAxis = {
      plotLines: [{
        label: {
          text: "Consumo en tiempo real",
          y: 0,
          verticalAlign: 'top',
          style: {
            color: '#FF0000',
            fontSize: 8
          }
        },
        color: '#FF0000',
        width: 1,
        value: parseInt(value)
      }]
    };
    */

    chart.chart.events = {
      complete: this.chargeM3()
    };

    Highcharts.chart('consuption_m3', chart);

  }

  private showRealtimeLtxMin(nodeId: string) {
    let chart = JSON.parse(JSON.stringify(Constants.base_chart));
    this.loading.charts_lt = true;
    chart.data = {
      csvURL: this.dashboardService.fetch_realtime_lt_min(nodeId),
      enablePolling: this.autService.getAuth().observers.length > 0,
      dataRefreshRate: parseInt("10", 10)
    };

    chart.yAxis = {
      gridLineDashStyle: 'dot',
      //softMax: 800,
      min: 0,
      title: {
        enabled: false,
        text: 'lt/min'
      }
    };

    chart.time =  {
      timezoneOffset: -new Date().getTimezoneOffset()
    }

    chart.chart.type =  'spline';
    chart.chart.events = {
      complete: this.chargeLt()
    };


    Highcharts.chart('consuption_lt', chart);
  }

  private showM3xHrBetweenDates(nodeId: string, from: Date, to: Date) {
      this.loading.charts_m3 = true;
      let fromDate = this.datePipe.transform(from,'ddMMyyy');
      let toDate = this.datePipe.transform(to,'ddMMyyy');
      let chart = JSON.parse(JSON.stringify(Constants.base_chart));

      chart.data = {
        csvURL: this.dashboardService.fetch_m3_hr_between_dates([{nodeId: nodeId}], fromDate, toDate)
      };

      chart.yAxis = {
        gridLineDashStyle: 'dot',
        //softMax: 50,
        min: 0,
        title: {
          enabled: false,
          text: 'm3/hr'
        }
      };


      if(this.aux != null) {
        if (from.getTimezoneOffset() && to.getTimezoneOffset()) {
          chart.time =  {
            timezoneOffset: -from.getTimezoneOffset()
          }
        }
      }

      chart.chart.events = {
        complete: this.chargeM3()
      };

      chart.plotOptions.series = {
        events: {
          click: (e) => {
            if(!this.auxDate.from && from.getTime() != to.getTime()) {

              console.log(this.auxDate.from);
              console.log(from.getTime());
              console.log(to.getTime());

              this.aux = nodeId;
              this.auxDate.from = from;
              this.auxDate.to = to;
              this.shorRealTimeLtEvent(e)
            }
          }
        }
      }

      Highcharts.chart('consuption_m3', chart);
  }

  public shorRealTimeLtEvent(event) {

    let day = Highcharts.dateFormat('%Y-%m-%d', event.point.x)

    console.log(day);

    let parts = day.split('-');

    this.finalDate = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
    //this.finalDate.setDate(this.finalDate.getDate() + 1);

    let date_from = this.datePipe.transform(this.finalDate,'ddMMyyy');
    let date_to = this.datePipe.transform(this.finalDate,'ddMMyyy');

    console.log("from. " + date_from + " to. " + date_to);

    this.retrieve_kpi(this.aux, date_from, date_to);

    this.showM3xHrBetweenDates(this.aux, this.finalDate, this.finalDate);
  }

  public volverAtras() {
    let date_from = this.datePipe.transform(this.auxDate.from,'ddMMyyy');
    let date_to = this.datePipe.transform(this.auxDate.to,'ddMMyyy');
    this.retrieve_kpi(this.aux, date_from, date_to);
    this.showM3xHrBetweenDates(this.aux, this.auxDate.from, this.auxDate.to);
    this.auxDate.from = null;
    this.auxDate.to = null;
  }

  private showM3xHrBetweenDatesAndMultiplesNodes(nodes: Array<any>, from: Date, to: Date) {
      let fromDate = this.datePipe.transform(from,'ddMMyyy');
      let toDate = this.datePipe.transform(to,'ddMMyyy');
      let chart = Object.assign({}, Constants.base_chart);
      let pie = Object.assign({}, Constants.base_pie_chart);
      let bar = Object.assign({}, Constants.base_bar_chart);

      chart.data = {
        csvURL: this.dashboardService.fetch_m3_hr_between_dates(nodes, fromDate, toDate)
      };

      chart.yAxis = {
        gridLineDashStyle: 'dot',
        //softMax: 100,
        min: 0,
        title: {
          enabled: false,
          text: 'm3/hr'
        }
      };

      if (from.getTimezoneOffset() && to.getTimezoneOffset()) {
        chart.time =  {
          timezoneOffset: -from.getTimezoneOffset()
        }
      }

      bar.series[0].data = [];
      bar.xAxis.categories = [];
      this.dashboardService.fetch_pie_chart(nodes, fromDate, toDate).subscribe((data: any[]) => {
          pie.series[0].data = data;
          Highcharts.chart('pi_multiple', pie);
          data.forEach(item => {
            
            bar.xAxis.categories.push(item.name);
            bar.series[0].data.push([item.name, item.y, item.x , item.z]);
          });
          Highcharts.chart('bar_chart', bar);
        }
      );

      Highcharts.chart('consuption_m3_multiple', chart);
  }

  setKpiAndAlerts(nodeId: string, from: Date, to: Date) {
    let date_from = this.datePipe.transform(from,'ddMMyyy');
    let date_to = this.datePipe.transform(to,'ddMMyyy');
    this.retrieve_kpi(nodeId, date_from, date_to);
    this.setAlerts([{"nodeId": nodeId}], date_from, date_to);
  }

  setKpiAndAlertsByMultiplesNodes(nodes: Array<any>, from: Date, to: Date) {
    let date_from = this.datePipe.transform(from,'ddMMyyy');
    let date_to = this.datePipe.transform(to,'ddMMyyy')
    this.retrieve_kpi_multiple(nodes, date_from, date_to);
    this.setAlerts(nodes, date_from, date_to);
  }

  retrieve_kpi(nodeId: string, start: any, end: any) {
    this.loading.kpi = true;
    this.nodeService.fetch_kpi(nodeId, start, end)
      .subscribe((data: any) => {
          this.setKpiValues(data);
          this.loading.kpi = false;
        }, () => {
          this.loading.kpi = false;
        }
      );
  }

  retrieve_kpi_multiple(array: Array<any>, start: any, end: any) {
    this.loading.kpi = true;
    this.nodeService.fetch_kpi_multiple(array, start, end)
      .subscribe((data: any) => {
          this.setKpiValues(data);
        this.loading.kpi = false;
        }, () => {
        this.loading.kpi = false;
        }
      );
  }

  setKpiValues(data: any) {
    this.kpi.consumption = parseFloat(data.consumption).toFixed(2);
    this.kpi.efficiency = parseFloat(data.efficiency).toFixed(1);
    this.kpi.savingM3 = parseFloat(data.savingM3).toFixed(2);
    this.kpi.savingAmount = parseFloat(data.savingAmount).toFixed(0);
    this.kpi.expenses = parseFloat(data.expenses).toFixed(0);
  }

  setAlerts(array, from: string, to: string) {
    let companies = [];
    array.forEach(function (object) {
      let companyId = object.nodeId.substr(0, 6);
      let found = companies.find(company => company.companyId === companyId);
      if(found) {
        found.nodes.push(object.nodeId);
      } else {
        companies.push({
          "companyId": companyId,
          "nodes": [object.nodeId]
        });
      }
    });

    this.nodeAlerts = [];
    this.alertBadge = 0;
    companies.forEach((object) => {
      this.nodeService.fetch_alerts_multiple(object.nodes, object.companyId, from, to).subscribe(value => {
        value.forEach((item) => {
          this.nodeAlerts.push(item);
          this.alertBadge += item.measureAlerts.length;
        });
      });
    });
  }

  chargeM3() {
    setTimeout(() => {
      this.loading.charts_m3 = false;
    }, 2000);
  }

  chargeLt() {
    setTimeout(() => {
      this.loading.charts_lt = false;
    }, 2000);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  openBottomSheet(): void {
    this._bottomSheet.open(WesDevicesSheetComponent, {
      data: {
        devices: this.devices
      }
    });
  }
}

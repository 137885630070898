import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }
  private host: string = "https://api-rest.wes.cl/wes/api/wes-mc-api/v1/";

  retrieve_user_id(): string {
    let url: string = localStorage.getItem("user-id");
    return url;
  }

  fetch_session_information(userId: string): Promise<any> {
    let url: string = this.host + `users/${ userId }/information`;
    const promise = new Promise((resolve, reject) => {
      const apiURL = url;
      this.httpClient
        .get<any[]>(apiURL)
        .toPromise()
        .then((res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  authenticate(username: string, password: string): Observable<string> {
    let url: string = this.host + `login`;
    let response: Observable<string> = this.httpClient.post(url, {username: username, password: password}, {responseType: 'text'});
    return response;
  }

  forgotPassword(email: string) : Observable<any> {
    let url: string = this.host + `forgot?email=${email}`;
    let response: Observable<any> = this.httpClient.post(url, {});
    return response;
  }

  changePassword(changePassword: any) : Observable<any> {
    let url: string = this.host + `changePassword?userId=${this.retrieve_user_id()}`;
    let response: Observable<any> = this.httpClient.post(url, changePassword);
    return response;
  }
}

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="'over'"
    [opened]="false"
  >
    <!--mat-toolbar style="background-color: white" class="mat-elevation-z0">
      <img style="width: 120px" src="/assets/logo.jpg">
    </mat-toolbar-->

    <mat-action-list style="min-width: 250px;">

      <h3 matSubheader>Menú</h3>
      <mat-divider></mat-divider>

      <button mat-list-item routerLink="/wes/control-panel" [routerLinkActive]="['is-active']">
        <mat-icon class="material-icons-outlined" matListIcon>insert_chart_outlined</mat-icon>
        <h3 matLine><b>Dashboard</b></h3>
        <p matLine><span>Panel general</span></p>
      </button>

    </mat-action-list>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-container">
    <wes-navigation-bar (toggleSidenav)="drawer.toggle()"></wes-navigation-bar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

  <!-- this is the sidenav we are concerned with -->
  <mat-sidenav #panel class="panel" position="end" disableClose>
    <!-- this is where i want to load different components dynamically -->
    <ng-container #content></ng-container>
  </mat-sidenav>
</mat-sidenav-container>


<div fxLayoutGap="32px" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
  <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
    <h1>Dispositivos</h1>
    <div *ngIf="devices">
      <p [hidden]="loading" class="mat-body-1" style="color: darkgrey">Wes ({{ devices.length }})</p>
    </div>
    <ngx-skeleton-loader
      [hidden]="!loading"
      count="1"
      appearance="circle"
      [theme]="{
                          width: '200px',
                          height: '10px',
                          'margin-left': '0px',
                          'margin-bottom': '0px',
                          'border-radius': '10px'
                        }">
    </ngx-skeleton-loader>

    <ngx-skeleton-loader
      fxShow fxHide.gt-md
      [hidden]="!loading"
      count="1"
      appearance="circle"
      [theme]="{
                          width: '270px',
                          height: '295px',
                          'margin-right': '5px',
                          'margin-left': '0px',
                          'margin-bottom': '0px',
                          'border-radius': '5px'
                        }"
    >
    </ngx-skeleton-loader>

    <div [hidden]="loading">
      <mat-tab-group style="height: 400px">
        <mat-tab label="{{item.name}}" *ngFor="let item of devices" >
          <div fxLayout="row" style="margin-top: 25px;" fxLayout.sm="column" fxLayout.xs="column" *ngIf="item.data">
            <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
              <table>
                <tr>
                  <td><small><b>Estado de dispositivo:</b></small></td>
                  <td><div class="statusIndicator" [ngClass]="{'active': isActive(item)}"></div></td>
                </tr>
                <tr>
                  <td><small><b>Dispositivo:</b></small></td>
                  <td><small><mat-chip class="mat-chip-device">{{item.nodeId}}</mat-chip></small></td>
                </tr>
                <tr>
                  <td><small><b>Hora de dispositivo:</b></small></td>
                  <td><small>{{item.data.wesHour}}</small></td>
                </tr>
                <tr>
                  <td><small><b>Fecha de dispositivo:</b></small></td>
                  <td><small>{{item.data.wesDate | date: 'dd/MM/yyyy'}}</small></td>
                </tr>
              </table>
            </div>
            <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
              <table>
                <tr>
                  <td><b><small>Ultima actualización de m3:</small></b> </td>
                  <td><small>{{item.data.measureUpdate | date: 'dd/MM/yyyy HH:mm'}}</small></td>
                </tr>
                <tr>
                  <td><small><b>Ultima conexión:</b></small> </td>
                  <td><small>{{item.data.lastUpdate | date: 'dd/MM/yyyy HH:mm'}}</small></td>
                </tr>
                <tr>
                  <td><small><b>Habilitación de consumo:</b></small></td>
                  <td><small><mat-chip class="mat-chip-device">{{item.data.wesStatus.toLowerCase()}}</mat-chip></small></td>
                </tr>
                <tr>
                  <td><small><b>Consumo ultima hora:</b></small></td>
                  <td><small>{{item.data.mch | number:'':'es'}} m3</small></td>
                </tr>
              </table>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/user/user.service";
import {FormControl, Validators} from "@angular/forms";
import {MyErrorStateMatcher} from "../wes-login/wes-login.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-wes-profile',
  templateUrl: './wes-profile.component.html',
  styleUrls: ['./wes-profile.component.scss']
})

export class WesProfileComponent implements OnInit {

  public loading = true;

  public response: any = {
    message: "",
    visible: false,
    error: false
  }


  public change: any = {
    visible: false,
    message: "",
    loading: false
  }

  public user_description: string;
  public company_name: string;
  public company_image: string;
  public session: string;

  public password = new FormControl({value: null, disabled: false}, [
    Validators.required
  ]);

  matcher = new MyErrorStateMatcher();

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.session = this.userService.retrieve_user_id();
    this.userService.fetch_session_information(this.session).then( value => {
      this.loading = false;
      this.user_description = `${value.name} ${value.lastName}`
      this.company_name = value.companyName;
      this.company_image = value.companyImage;
    });
  }

  changePassword(password: string) {
    if (this.password.invalid) {
      this.password.markAsTouched();
      return
    }

    this.change.loading = true;
    this.userService.changePassword({email: "", password: password})
      .subscribe(data => {
        this.change.loading = false;
        this.response.visible=true;
        this.response.message = "Cambio realizado!";
      }, error => {
        this.change.loading = false;
        this.response.message = "Error al modificar contraseña.";
        this.response.error = true;
        this.response.visible=true;
      });
  }

  volver() {
    this.router.navigateByUrl("wes/control-panel");
  }

}

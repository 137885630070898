import {Component, Input, OnInit} from '@angular/core';
import {SidenavService} from "../../services/sidenav/sidenav.service";

@Component({
  selector: 'wes-sidenav-alerts',
  templateUrl: './wes-sidenav-alerts.component.html',
  styleUrls: ['./wes-sidenav-alerts.component.scss']
})
export class WesSidenavAlertsComponent implements OnInit {

  @Input('alerts') nodeAlerts: any;
  constructor(public sidenavService: SidenavService) { }

  ngOnInit(): void {
  }

}

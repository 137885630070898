<mat-toolbar style="background-color: white" class="mat-elevation-z2">
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md="end">
    <div
        fxFlex.xl="50"
        fxFlex.lt-xl="66"
        style="flex: 1 1 auto; box-sizing: border-box;"
    >
      <mat-toolbar-row>
        <img style="width: 130px" align="left" src="/assets/logo.png">
        <span class="example-spacer"></span>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="viewProfile()">
            <mat-icon>settings</mat-icon>
            <small>Perfil</small>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="closeSession()">
            <mat-icon>power_settings_new</mat-icon>
            <small>Cerrar sesión</small>
          </button>
        </mat-menu>
        <button mat-stroked-button [matMenuTriggerFor]="menu"
                matTooltip='{{user_description}}'
                color="accent">
          <div>
            <div *ngIf="company_image" mat-card-avatar style="float:left; margin-right: 5px;background-size: cover;"
                 [ngStyle]="{'background-image': 'url(' + company_image + ')'}"></div>
            <small style="float:left;">{{company_name}}</small>
          </div>
        </button>
      </mat-toolbar-row>
    </div>
  </div>


</mat-toolbar>

<div class="sidenav-container">
  <mat-toolbar color="accent" class="mat-elevation-z3">
    <mat-icon style="margin-right: 5px;">supervisor_account</mat-icon>
    <span>Perfil</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="sidenavService.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

</div>




import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  private subject = new Subject<any>();
  constructor() { }
  
  logout() {
      this.subject.next();
  }

  getAuth(): Subject<any> {
    return this.subject;
  }

}

<div fxLayout="row" fxLayoutAlign="start" style="background-color: #054666">
  <owl-carousel-o style="width: 100%; margin-top: 1px;" [options]="customOptions" fxHide.gt-md>
    <ng-template carouselSlide *ngIf="this.kpi.efficiency > 0">
      <div fxLayout="row" fxLayoutAlign="end">
        <button style="color: white;" mat-icon-button (click)="sidenavService.open(searchContentSidenav)">
          <mat-icon >more_vert</mat-icon>
        </button>
      </div>
      <mat-card class="mat-elevation-z0" style="background-color: transparent" [fxShow]="this.kpi.efficiency > 0">
        <mat-card-header>
          <mat-card-title>
              <h1 style="color: white">
                Eficiencia <mat-icon inline>military_tech</mat-icon>
              </h1>
          </mat-card-title>
          <mat-card-subtitle>

            <h5 [hidden]="loading.devices" style="color: white"
                *ngIf="devices && (globalDates.from != globalDates.to)">
              {{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.from}}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.to}}
              <button *ngIf="devices && devices.length > 1" style="color: white;" mat-icon-button (click)="openBottomSheet()">
                <mat-icon [inline]="true">keyboard_arrow_down</mat-icon>
              </button>
            </h5>

            <h5 [hidden]="loading.devices" style="color: white"
                *ngIf="devices && (globalDates.from == globalDates.to)">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.from}}

              <button *ngIf="devices && devices.length > 1" style="color: white;" mat-icon-button (click)="openBottomSheet()">
                <mat-icon [inline]="true">keyboard_arrow_down</mat-icon>
              </button>
            </h5>

          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <h1 align="right" style="color: #35b6f8;">{{this.kpi.efficiency | number:'':'es' }} %</h1>
        </mat-card-content>
      </mat-card>
    </ng-template>
    <ng-template carouselSlide *ngIf="this.kpi.efficiency > 0">
      <div fxLayout="row" fxLayoutAlign="end">
        <button style="color: white;" mat-icon-button (click)="sidenavService.open(searchContentSidenav)">
          <mat-icon >more_vert</mat-icon>
        </button>
      </div>
      <mat-card class="mat-elevation-z0" style="background-color: transparent">
        <mat-card-header>
          <mat-card-title>
            <h1 style="color: white">Ahorro
              <mat-icon inline>eco</mat-icon>
            </h1>
          </mat-card-title>
          <mat-card-subtitle>

            <h5 [hidden]="loading.devices" style="color: white"
                *ngIf="devices && (globalDates.from != globalDates.to)">
              {{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.from}}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.to}}

              <button *ngIf="devices && devices.length > 1" style="color: white;" mat-icon-button (click)="openBottomSheet()">
                <mat-icon [inline]="true">keyboard_arrow_down</mat-icon>
              </button>
            </h5>

            <h5 [hidden]="loading.devices" style="color: white"
                *ngIf="devices && (globalDates.from == globalDates.to)">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.from}}

              <button *ngIf="devices && devices.length > 1" style="color: white;" mat-icon-button (click)="openBottomSheet()">
                <mat-icon [inline]="true">keyboard_arrow_down</mat-icon>
              </button>
            </h5>

          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <h1 align="right" style="color: #35b6f8;;">{{this.kpi.savingM3 | number:'':'es' }} m3 | $ {{this.kpi.savingAmount | number:'':'es'}}</h1>
        </mat-card-content>
      </mat-card>
    </ng-template>
    <ng-template carouselSlide>
      <div fxLayout="row" fxLayoutAlign="end">
        <button style="color: white;" mat-icon-button (click)="sidenavService.open(searchContentSidenav)">
          <mat-icon >more_vert</mat-icon>
        </button>
      </div>
      <mat-card class="mat-elevation-z0" style="background-color: transparent">
        <mat-card-header>
          <mat-card-title>
            <h1 style="color: white">Gastos
              <mat-icon inline>monetization_on</mat-icon>
            </h1>
          </mat-card-title>
          <mat-card-subtitle>

            <h5 [hidden]="loading.devices" style="color: white"
                *ngIf="devices && (globalDates.from != globalDates.to)">
              {{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.from}}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.to}}

              <button *ngIf="devices && devices.length > 1" style="color: white;" mat-icon-button (click)="openBottomSheet()">
                <mat-icon [inline]="true">keyboard_arrow_down</mat-icon>
              </button>
            </h5>

            <h5 [hidden]="loading.devices" style="color: white"
                *ngIf="devices && (globalDates.from == globalDates.to)">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.from}}

              <button *ngIf="devices && devices.length > 1" style="color: white;" mat-icon-button (click)="openBottomSheet()">
                <mat-icon [inline]="true">keyboard_arrow_down</mat-icon>
              </button>
            </h5>

          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <h1 align="right" style="color: #35b6f8;;">$ {{kpi.expenses | number:'':'es'}}</h1>
        </mat-card-content>
      </mat-card>
    </ng-template>
    <ng-template carouselSlide>
      <div fxLayout="row" fxLayoutAlign="end">
        <button style="color: white;" mat-icon-button (click)="sidenavService.open(searchContentSidenav)">
          <mat-icon >more_vert</mat-icon>
        </button>
      </div>
      <mat-card class="mat-elevation-z0" style="background-color: transparent">
        <mat-card-header>
          <mat-card-title>
            <h1 style="color: white">Consumo
              <mat-icon inline>wash</mat-icon>
            </h1>
          </mat-card-title>
          <mat-card-subtitle>

            <h5 [hidden]="loading.devices" style="color: white"
                *ngIf="devices && (globalDates.from != globalDates.to)">
              {{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.from}}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.to}}

              <button *ngIf="devices && devices.length > 1" style="color: white;" mat-icon-button (click)="openBottomSheet()">
                <mat-icon [inline]="true">keyboard_arrow_down</mat-icon>
              </button>
            </h5>

            <h5 [hidden]="loading.devices" style="color: white"
                *ngIf="devices && (globalDates.from == globalDates.to)">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
              <mat-icon [inline]="true">arrow_right</mat-icon>
              {{globalDates.from}}

              <button *ngIf="devices && devices.length > 1" style="color: white;" mat-icon-button (click)="openBottomSheet()">
                <mat-icon [inline]="true">keyboard_arrow_down</mat-icon>
              </button>
            </h5>

          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <h1 align="right" style="color: #35b6f8;">{{kpi.consumption | number:'':'es'}} m3</h1>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </owl-carousel-o>
</div>

<div fxLayout="row" fxLayoutGap="0px" fxLayoutAlign="space-evenly center" fxHide.gt-sm>
<div class="button-icon-device">
  <button *ngIf="connectedDevices.length > 0" mat-mini-fab class="mat-elevation-z1" matBadge="{{connectedDevices.length}}" (click)="sidenavService.open(connectedDevicesSidenav)">
    <mat-icon style="color: #35b6f8">signal_cellular_alt</mat-icon>
  </button>
  <button *ngIf="connectedDevices.length == 0" style="background-color: #ededed" mat-mini-fab class="mat-elevation-z0">
    <mat-icon style="color: darkgray">signal_cellular_alt</mat-icon>
  </button>
  <small>Conectados</small>
</div>
  <div class="button-icon-device">
    <button *ngIf="disconnectedDevices.length > 0" mat-mini-fab class="mat-elevation-z1" matBadge="{{disconnectedDevices.length}}" (click)="sidenavService.open(disconnectedDevicesSidenav)">
      <mat-icon style="color: lightgray">signal_cellular_connected_no_internet_4_bar</mat-icon>
    </button>
    <button *ngIf="disconnectedDevices.length == 0" style="background-color: #ededed" mat-mini-fab class="mat-elevation-z0" >
      <mat-icon style="color: darkgray">signal_cellular_connected_no_internet_4_bar</mat-icon>
    </button>
    <small>Sin conexión</small>
  </div>
  <div class="button-icon-device">
    <button *ngIf="!loading.devices && alertBadge > 0" mat-mini-fab class="mat-elevation-z1" matBadge="{{alertBadge}}" matBadgeColor="warn" (click)="sidenavService.open(alertSidenav)">
      <mat-icon style="color: lightcoral">warning</mat-icon>
    </button>
    <button *ngIf="!alertBadge || alertBadge === 0" mat-mini-fab class="mat-elevation-z0" style="background-color: #ededed" >
      <mat-icon style="color: darkgray">warning</mat-icon>
    </button>
    <small>Alertas</small>
  </div>
</div>
<div class="container">

  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
    <div fxFlex.xl="50" fxFlex.lt-xl="66" style="flex: 1 1 auto; box-sizing: border-box;">

      <div fxLayoutGap="25px" fxLayoutGap.lt-xl="20px" fxLayoutGap.lt-md="32px" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">

        <section>

          <h1 fxHide.lt-xl>Dashboard</h1>
          <div fxHide.xl fxLayout="row" fxLayoutAlign="space-between center">
            <h2 fxHide.lt-md>Dashboard</h2>
          </div>

          <h5 fxHide.lt-md [hidden]="loading.devices" style="color: darkgrey"
              *ngIf="devices && (globalDates.from != globalDates.to)">
            {{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
            <mat-icon [inline]="true">arrow_right</mat-icon>
            {{globalDates.from}}
            <mat-icon [inline]="true">arrow_right</mat-icon>
            {{globalDates.to}} </h5>

          <h5 fxHide.lt-md [hidden]="loading.devices" style="color: darkgrey"
              *ngIf="devices && (globalDates.from == globalDates.to)">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices[0].name }}
            <mat-icon [inline]="true">arrow_right</mat-icon>
            {{globalDates.from}}</h5>

          <ngx-skeleton-loader fxHide.lt-md [hidden]="!loading.devices" count="1" appearance="circle" x
                               [theme]="{width: '200px',height: '10px','margin-left': '0px','border-radius': '10px'}">
          </ngx-skeleton-loader>

        </section>


        <section fxHide.lt-md>
          <ngx-skeleton-loader fxHide fxShow.gt-sm [hidden]="!loading.kpi" count="1" appearance="circle"
                               [theme]="{width: '100%',height: '120px','margin-right': '5px','margin-left': '0px','margin-bottom': '0px','border-radius': '5px'}"></ngx-skeleton-loader>
          <div [hidden]="loading.kpi">
            <mat-card class="kpi-container mat-elevation-z2">
              <mat-card-content>
                <div fxLayout="row" fxLayoutAlign="space-evenly none">
                  <mat-card class="mat-elevation-z0">
                    <mat-card-header [fxShow]="this.kpi.efficiency > 0">
                      <mat-icon mat-card-avatar>military_tech</mat-icon>
                      <mat-card-title>
                        Eficiencia
                      </mat-card-title>
                      <mat-card-subtitle>
                        <h4 align="center" style="color: #35b6f8;; margin-top: 10px;"
                            class="kpi-subtitle">{{this.kpi.efficiency | number:'':'es' }} %</h4>
                      </mat-card-subtitle>
                    </mat-card-header>
                  </mat-card>

                  <mat-card class="mat-elevation-z0" [fxShow]="this.kpi.efficiency > 0" >
                    <mat-card-header>
                      <mat-icon mat-card-avatar>eco</mat-icon>
                      <mat-card-title>
                        Ahorro
                      </mat-card-title>
                      <mat-card-subtitle>
                        <h4 fxShow.sm align="center" style="color: #35b6f8;">{{this.kpi.savingM3 | number:'':'es' }}
                          <sup>m3</sup> | $ {{this.kpi.savingAmount | number:'':'es'}}</h4>
                      </mat-card-subtitle>
                    </mat-card-header>
                  </mat-card>

                  <mat-card class="mat-elevation-z0">
                    <mat-card-header>
                      <mat-icon mat-card-avatar style="color: darkgrey">monetization_on</mat-icon>
                      <mat-card-title>
                        Gastos
                      </mat-card-title>
                      <mat-card-subtitle>
                        <h4 align="center" style="color: #35b6f8;; margin-top: 10px;">$ {{kpi.expenses | number:'':'es'}}</h4>
                      </mat-card-subtitle>
                    </mat-card-header>
                  </mat-card>

                  <mat-card class="mat-elevation-z0">
                    <mat-card-header>
                      <mat-icon mat-card-avatar style="color: darkgrey">wash</mat-icon>
                      <mat-card-title>
                        Consumo
                      </mat-card-title>
                      <mat-card-subtitle>
                        <h4 align="center" style="color: #35b6f8;">{{kpi.consumption | number:'':'es'}} <sup>m3</sup>
                        </h4>
                      </mat-card-subtitle>
                    </mat-card-header>
                  </mat-card>

                  <div fxLayout="row" fxLayoutAlign="end start">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="sidenavService.open(searchContentSidenav)">
                        <mat-icon>find_in_page</mat-icon>
                        <span>Filtrar contenido</span>
                      </button>
                      <button mat-menu-item (click)="createReport()">
                        <mat-icon>receipt_long</mat-icon>
                        <span>Descargar CSV</span>
                      </button>
                      <button mat-menu-item (click)="createReportPdf()">
                        <mat-icon>picture_as_pdf</mat-icon>
                        <span>Descargar PDF</span>
                      </button>
                    </mat-menu>

                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </section>

        <section fxHide="{{devices && devices.length <= 1}}" >
          <div fxLayout="row" fxLayoutGap="32px" fxLayoutGap.lt-xl="10px" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="end">
            
            <section fxLayout="column">

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <h1 fxHide.lt-xl>Consumo (m3/{{globalDates.from != globalDates.to ? 'día' : 'hr'}})</h1>
                <h2 fxHide.lt-md fxHide.xl>Consumo (m3/{{globalDates.from != globalDates.to ? 'día' : 'hr'}})</h2>
                <h4 fxHide.gt-sm>Consumo (m3/{{globalDates.from != globalDates.to ? 'día' : 'hr'}})</h4>
                <button mat-icon-button *ngIf="auxDate.from && !loading.charts_m3" (click)="volverAtras()" matTooltip="Volver a grafica general">
                  <mat-icon style="color: #35b6f8;">restore</mat-icon>
                </button>
              </div>

              <h5 [hidden]="loading.charts_m3" style="color: darkgrey" *ngIf="devices && globalDates.from != globalDates.to">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices.length + " dispositivo seleccionado" }}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.from}}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.to}}
                <div *ngIf="auxDate.from && !loading.charts_m3" style="color: #35b6f8;">
                  <mat-icon [inline]="true">event</mat-icon>
                  {{ finalDate | date: 'd MMMM y'}} - Escala representada en m3/hr
                </div>
              </h5>

              <h5 [hidden]="loading.charts_m3" style="color: darkgrey"
                  *ngIf="devices && globalDates.from === globalDates.to">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices.length + " dispositivo seleccionado" }}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.from}}</h5>
              <ngx-skeleton-loader [hidden]="!loading.charts_m3" count="1" appearance="circle"
                                   [theme]="{width: '200px',height: '10px','margin-left': '0px','border-radius': '10px'}"></ngx-skeleton-loader>

              <div id="consuption_m3_multiple" class="chart" [hidden]="loading.charts_m3"></div>

              <!--div fxLayout="row" fxLayoutAlign="space-between center" (click)="sidenavService.open(auditoriesSidenav)" style="cursor: pointer;">
                <h5 style="color: #35b6f8;"><mat-icon inline style="color: #f87935">error_outline</mat-icon> El periodo seleccionado contiene auditoria(s)</h5>
              </div-->

              <ngx-skeleton-loader
                fxHide.xl fxHide.lt-md
                [hidden]="!loading.charts_m3"
                count="1"
                appearance="circle"
                [theme]="{height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.lt-xl fxHide.lt-md
                [hidden]="!loading.charts_m3"
                count="1"
                appearance="circle"
                [theme]="{width: '440px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.gt-md
                [hidden]="!loading.charts_m3"
                count="1"
                appearance="circle"
                [theme]="{width: '340px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

            </section>

            <section fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <h1 fxHide.lt-xl>Análisis</h1>
                <h2 fxHide.lt-md fxHide.xl>Análisis</h2>
                <h4 fxHide.gt-sm>Análisis</h4>
              </div>
              <h5 [hidden]="loading.charts_m3" style="color: darkgrey"
                *ngIf="devices && globalDates.from != globalDates.to">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices.length + " dispositivo seleccionado" }}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.from}}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.to}}

                <div *ngIf="auxDate.from && !loading.charts_m3" style="color: #35b6f8;">
                  <mat-icon [inline]="true">event</mat-icon>
                  {{ finalDate | date: 'd MMMM y'}} - Escala representada en m3/hr
                </div>
              </h5>

              <h5 [hidden]="loading.charts_m3" style="color: darkgrey"
                  *ngIf="devices && globalDates.from === globalDates.to">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices.length + " dispositivo seleccionado" }}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.from}}</h5>

              
              <div fxHide.lt-md fxHide.sm id="pi_multiple" class="chart_multiple" [hidden]="loading.charts_m3"></div>
              <div fxHide.gt-sm id="bar_chart" class="chart_bar" [hidden]="loading.charts_m3"></div>
              
            </section>
            
          </div>
        </section>

        <section fxShow="{{!devices}}" >

          <div fxLayout="row" fxLayoutGap="32px" fxLayoutGap.lt-xl="10px" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="end">
            <section fxLayout="column">

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <h1 fxHide.lt-xl>Consumo (m3/{{globalDates.from != globalDates.to ? 'día' : 'hr'}})</h1>
                <h2 fxHide.lt-md fxHide.xl>Consumo (m3/{{globalDates.from != globalDates.to ? 'día' : 'hr'}})</h2>
                <h4 fxHide.gt-sm>Consumo (m3/{{globalDates.from != globalDates.to ? 'día' : 'hr'}})</h4>
                <button mat-icon-button *ngIf="auxDate.from && !loading.charts_m3" (click)="volverAtras()" matTooltip="Volver a grafica general">
                  <mat-icon style="color: #35b6f8;">restore</mat-icon>
                </button>
              </div>

              <h5 [hidden]="loading.charts_m3" style="color: darkgrey" *ngIf="devices && globalDates.from != globalDates.to">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices.length + " dispositivo seleccionado" }}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.from}}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.to}}

                <div *ngIf="auxDate.from && !loading.charts_m3" style="color: #35b6f8;">
                  <mat-icon [inline]="true">event</mat-icon>
                  {{ finalDate | date: 'd MMMM y'}} | Escala representada en m3/hr
                </div>
              </h5>

              <h5 [hidden]="loading.charts_m3" style="color: darkgrey" *ngIf="devices && globalDates.from === globalDates.to">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices.length + " dispositivo seleccionado" }}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.from}}
              </h5>
              <ngx-skeleton-loader [hidden]="!loading.charts_m3" count="1" appearance="circle" [theme]="{width: '200px',height: '10px','margin-left': '0px','border-radius': '10px'}"></ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.xl fxHide.lt-md
                [hidden]="!loading.charts_m3"
                count="1"
                appearance="circle"
                [theme]="{width: '448px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.lt-xl fxHide.lt-md
                [hidden]="!loading.charts_m3"
                count="1"
                appearance="circle"
                [theme]="{width: '440px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.gt-md
                [hidden]="!loading.charts_m3"
                count="1"
                appearance="circle"
                [theme]="{width: '340px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

            </section>

            <section>
              <h1 fxHide.lt-xl>Consumo (lt/min)</h1>
              <h2 fxHide.lt-md fxHide.xl>Consumo (lt/min)</h2>
              <h4 fxHide.gt-sm>Consumo (lt/min)</h4>
              <h5 [hidden]="loading.charts_lt" style="color: darkgrey" class="p-chart"
                  *ngIf="devices">{{ devices.length <= 1 ? 'Ultimas 30 conexiones' : 'No disponible'}}</h5>

              <ngx-skeleton-loader
                [hidden]="!loading.charts_lt"
                count="1"
                appearance="circle"
                [theme]="{width: '200px',height: '10px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.xl fxHide.lt-md
                [hidden]="!loading.charts_lt"
                count="1"
                appearance="circle"
                [theme]="{width: '448px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.lt-xl fxHide.lt-md
                [hidden]="!loading.charts_lt"
                count="1"
                appearance="circle"
                [theme]="{width: '440px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.gt-md
                [hidden]="!loading.charts_lt"
                count="1"
                appearance="circle"
                [theme]="{width: '340px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>
            </section>
          </div>
        </section>


        <section fxHide="{{devices && devices.length > 1}}" >

          <div fxLayout="row" fxLayoutGap="32px" fxLayoutGap.lt-xl="10px" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="end">
            <section fxLayout="column">

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <h1 fxHide.lt-xl>Consumo (m3/{{globalDates.from != globalDates.to ? 'día' : 'hr'}})</h1>
                <h2 fxHide.lt-md fxHide.xl>Consumo (m3/{{globalDates.from != globalDates.to ? 'día' : 'hr'}})</h2>
                <h4 fxHide.gt-sm>Consumo (m3/{{globalDates.from != globalDates.to ? 'día' : 'hr'}})</h4>
                <button mat-icon-button *ngIf="auxDate.from && !loading.charts_m3" (click)="volverAtras()" matTooltip="Volver a grafica general">
                  <mat-icon style="color: #35b6f8;">restore</mat-icon>
                </button>
              </div>

              <h5 [hidden]="loading.charts_m3" style="color: darkgrey" *ngIf="devices && globalDates.from != globalDates.to">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices.length + " dispositivo seleccionado" }}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.from}}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.to}}

                <div *ngIf="auxDate.from && !loading.charts_m3" style="color: #35b6f8;">
                  <mat-icon [inline]="true">event</mat-icon>
                  {{ finalDate | date: 'd MMMM y'}} | Escala representada en m3/hr
                </div>
              </h5>

              <h5 [hidden]="loading.charts_m3" style="color: darkgrey"
                  *ngIf="devices && globalDates.from === globalDates.to">{{ devices.length > 1 ? devices.length + " dispositivos seleccionados" : devices.length + " dispositivo seleccionado" }}
                <mat-icon [inline]="true">arrow_right</mat-icon>
                {{globalDates.from}}</h5>
              <ngx-skeleton-loader [hidden]="!loading.charts_m3" count="1" appearance="circle"
                                   [theme]="{width: '200px',height: '10px','margin-left': '0px','border-radius': '10px'}"></ngx-skeleton-loader>

              <div id="consuption_m3" class="chart" [hidden]="loading.charts_m3"></div>

              <!--div fxLayout="row" fxLayoutAlign="space-between center" (click)="sidenavService.open(auditoriesSidenav)" style="cursor: pointer;">
                <h5 style="color: #35b6f8;"><mat-icon inline style="color: #f87935">error_outline</mat-icon> El periodo seleccionado contiene auditoria(s)</h5>
              </div-->

              <ngx-skeleton-loader
                fxHide.xl fxHide.lt-md
                [hidden]="!loading.charts_m3"
                count="1"
                appearance="circle"
                [theme]="{width: '448px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.lt-xl fxHide.lt-md
                [hidden]="!loading.charts_m3"
                count="1"
                appearance="circle"
                [theme]="{width: '440px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.gt-md
                [hidden]="!loading.charts_m3"
                count="1"
                appearance="circle"
                [theme]="{width: '340px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

            </section>

            <section>
              <h1 fxHide.lt-xl>Consumo (lt/min)</h1>
              <h2 fxHide.lt-md fxHide.xl>Consumo (lt/min)</h2>
              <h4 fxHide.gt-sm>Consumo (lt/min)</h4>
              <h5 [hidden]="loading.charts_lt" style="color: darkgrey" class="p-chart"
                  *ngIf="devices">{{ devices.length <= 1 ? 'Ultimas 30 conexiones' : 'No disponible'}}</h5>

              <ngx-skeleton-loader
                [hidden]="!loading.charts_lt"
                count="1"
                appearance="circle"
                [theme]="{width: '200px',height: '10px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.xl fxHide.lt-md
                [hidden]="!loading.charts_lt"
                count="1"
                appearance="circle"
                [theme]="{width: '448px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.lt-xl fxHide.lt-md
                [hidden]="!loading.charts_lt"
                count="1"
                appearance="circle"
                [theme]="{width: '440px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader
                fxHide.gt-md
                [hidden]="!loading.charts_lt"
                count="1"
                appearance="circle"
                [theme]="{width: '340px',height: '170px','margin-left': '0px','border-radius': '10px'}">
              </ngx-skeleton-loader>
              
              <div fxHide="{{devices && devices.length > 1}}" id="consuption_lt" class="chart" [hidden]="loading.charts_lt"></div>

            </section>
          </div>
        </section>

        <section fxHide.lt-md>
          <h1 fxHide.lt-xl>Dispositivos</h1>
          <h2 fxHide.lt-md fxHide.xl>Dispositivos</h2>
          <h4 fxHide.gt-sm>Dispositivos</h4>
          <h5 [hidden]="loading.devices" class="mat-body-1" style="color: darkgrey">Wes
            ({{connectedDevices.length + disconnectedDevices.length}})</h5>
          <ngx-skeleton-loader [hidden]="!loading.devices" count="1" appearance="circle"
                               [theme]="{width: '200px',height: '10px','margin-left': '0px','border-radius': '10px'}"></ngx-skeleton-loader>
          <div fxLayout="row" fxLayoutGap="32px" fxLayoutGap.lt-md="10px" fxLayout.sm="column" fxLayout.xs="column"
               fxLayoutAlign="start start">
            <ngx-skeleton-loader [hidden]="!loading.devices" count="1" appearance="circle"
                                 [theme]="{width: '280px',height: '95px','margin-left': '0px','border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [hidden]="!loading.devices" count="1" appearance="circle"
                                 [theme]="{width: '280px',height: '95px','margin-left': '0px','border-radius': '10px'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [hidden]="!loading.devices" count="1" appearance="circle"
                                 [theme]="{width: '280px',height: '95px','margin-left': '0px','border-radius': '10px'}"></ngx-skeleton-loader>

            <mat-card [fxShow]="!loading.devices && connectedDevices.length > 0" class="device-container mat-elevation-z3"
                      matRipple
                      fxFlexOrder.lt-md="2"
                      [matRippleCentered]="centered"
                      [matRippleDisabled]="disabled"
                      [matRippleUnbounded]="unbounded"
                      [matRippleRadius]="radius"
                      [matRippleColor]="color"
                      (click)="sidenavService.open(connectedDevicesSidenav)">
              <mat-card-header>
                <mat-icon mat-card-avatar>signal_cellular_alt</mat-icon>
                <mat-card-title>Conectados ({{connectedDevices.length}})</mat-card-title>
                <mat-card-subtitle>Dispositivos con conexión estable.</mat-card-subtitle>
              </mat-card-header>
            </mat-card>

            <mat-card [fxShow]="!loading.devices && connectedDevices.length == 0" class="device-container mat-elevation-z1"
                      style="color: lightgray"
                      fxFlexOrder.lt-md="2">
              <mat-card-header>
                <mat-icon mat-card-avatar>signal_cellular_alt</mat-icon>
                <mat-card-title>Conectados ({{connectedDevices.length}})</mat-card-title>
                <mat-card-subtitle>Dispositivos con conexión estable.</mat-card-subtitle>
              </mat-card-header>
            </mat-card>

            <mat-card [fxShow]="!loading.devices && disconnectedDevices.length >= 1" class="device-container mat-elevation-z3"
                      matRipple
                      fxFlexOrder.lt-md="3"
                      [matRippleCentered]="centered"
                      [matRippleDisabled]="disabled"
                      [matRippleUnbounded]="unbounded"
                      [matRippleRadius]="radius"
                      [matRippleColor]="color"
                      (click)="sidenavService.open(disconnectedDevicesSidenav)">
              <mat-card-header>
                <mat-icon mat-card-avatar style="color: darkgray">signal_cellular_connected_no_internet_4_bar</mat-icon>
                <mat-card-title>Sin conexión ({{disconnectedDevices.length}})</mat-card-title>
                <mat-card-subtitle>Dispositivos sin conexión por mas de 2 horas.</mat-card-subtitle>
              </mat-card-header>
            </mat-card>

            <mat-card [fxShow]="!loading.devices && disconnectedDevices.length == 0" class="device-container mat-elevation-z1"
                      style="color: lightgray"
                      fxFlexOrder.lt-md="3">
              <mat-card-header>
                <mat-icon mat-card-avatar style="color: darkgray">signal_cellular_connected_no_internet_4_bar</mat-icon>
                <mat-card-title>Sin conexión ({{disconnectedDevices.length}})</mat-card-title>
                <mat-card-subtitle style="color: lightgray">Dispositivos sin conexión por mas de 2 horas.</mat-card-subtitle>
              </mat-card-header>
            </mat-card>

            <mat-card [fxShow]="!loading.devices && alertBadge > 0" class="device-container mat-elevation-z3"
                      matRipple
                      fxFlexOrder.lt-md="1"
                      [matRippleCentered]="centered"
                      [matRippleDisabled]="disabled"
                      [matRippleUnbounded]="unbounded"
                      [matRippleRadius]="radius"
                      [matRippleColor]="color"
                      (click)="sidenavService.open(alertSidenav)">
              <mat-card-header>
                <mat-icon mat-card-avatar style="color: darkorange">warning</mat-icon>
                <mat-card-title>Alertas ({{alertBadge}})</mat-card-title>
                <mat-card-subtitle>Consumo constante por tiempo prolongado</mat-card-subtitle>
              </mat-card-header>
            </mat-card>

            <mat-card [fxShow]="!loading.devices" class="device-container mat-elevation-z1" *ngIf="alertBadge === 0"
                      style="color: lightgray">
              <mat-card-header>
                <mat-icon mat-card-avatar style="color: lightgray">warning</mat-icon>
                <mat-card-title>Alertas</mat-card-title>
                <mat-card-subtitle style="color: lightgray">Consumo constante por tiempo prolongado</mat-card-subtitle>
              </mat-card-header>
            </mat-card>
          </div>


        </section>

      </div>
    </div>
  </div>

</div>

<ng-template #searchContentSidenav>
  <wes-sidenav-search-content (searchEvent)="searchFilter($event)" [nodes]="user_data.allowedNodes"
                              [activeNode]="devices"
                              [activeFilter]="searchFilterParameters"
  ></wes-sidenav-search-content>
</ng-template>

<ng-template #alertSidenav>
  <wes-sidenav-alerts [alerts]="nodeAlerts"></wes-sidenav-alerts>
</ng-template>

<ng-template #connectedDevicesSidenav>
  <wes-sidenav-devices [nodes]="connectedDevices" [connected]=true></wes-sidenav-devices>
</ng-template>

<ng-template #disconnectedDevicesSidenav>
  <wes-sidenav-devices [nodes]="disconnectedDevices" [connected]=false></wes-sidenav-devices>
</ng-template>

<!--ng-template #auditoriesSidenav>
  <wes-auditories></wes-auditories>
</ng-template-->


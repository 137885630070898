import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {WesLoginLayoutComponent} from "./views/wes-login-layout/wes-login-layout.component";
import {WesLoginComponent} from "./views/wes-login/wes-login.component";
import {WesControlPanelComponent} from "./views/wes-control-panel/wes-control-panel.component";
import {WesHomeLayoutComponent} from "./views/wes-home-layout/wes-home-layout.component";
import {WesProfileComponent} from "./views/wes-profile/wes-profile.component";

const routes: Routes = [
  { path: '', redirectTo: 'login', data: { title: 'First Component' }, pathMatch: 'full' },
  {
    path: 'login', component: WesLoginLayoutComponent, data: {title: 'First Component'},
    children: [
      {path: '', component: WesLoginComponent}
    ]
  },
  { path: 'wes', component: WesHomeLayoutComponent,
    children: [
      { path: '', redirectTo: 'control-panel', pathMatch: 'full' },
      { path: 'control-panel', component: WesControlPanelComponent },
      { path: 'profile', component: WesProfileComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) { }
  private host: string = "https://api-rest.wes.cl/wes/api/acl-node/v1/";

  fetch_realtime_lt_min(nodeId: string): string {
    let url: string = this.host + "nodes/"+ nodeId + "/liters.per.minute.csv";
    return url;
  }

  fetch_realtime_m3_hr(nodeId: string): string {
    let url: string = this.host + "nodes/"+ nodeId + "/realtime.measures.csv";
    return url;
  }

  fetch_m3_hr_between_dates(array: Array<any>, from: string, to: string): string {
    let url: string = this.host + "nodes/dates.measures.csv";
    array.forEach(function (object, index) {
      if(index == 0 &&  object.nodeId) {
        url = url + "?id=" + object.nodeId;
      } else if(object.nodeId) {
        url = url + "&id=" + object.nodeId;
      }
    });
    url = url + "&end="+to+"&start="+ from;
    return url;
  }

  fetch_pie_chart(array: Array<any>, from: string, to: string): Observable<any[]> {
    let url: string = this.host + "nodes/charts/pie";
    array.forEach(function (object, index) {
      if(index == 0 &&  object.nodeId) {
        url = url + "?id=" + object.nodeId;
      } else if(object.nodeId) {
        url = url + "&id=" + object.nodeId;
      }
    });
    url = url + "&end="+to+"&start="+ from;
    return <Observable<any[]>> this.httpClient.get<any[]>(url);
  }

  fetch_realtime_m3_h_multiple(array: Array<any>): string {
    let url: string = this.host + "nodes/realtime.measures.csv";
    array.forEach(function (object, index) {
      if(index == 0) {
        url = url + "?id=" + object.nodeId;
      } else {
        url = url + "&id=" + object.nodeId;
      }
    });
    return url;
  }

  fetch_alerts(array: Array<any>): Promise<any> {
    let url: string = this.host + `nodes/myalert/alerts`;
    array.forEach(function (object, index) {
      console.log(object);
      if(index == 0) {
        url = `${url}?id=${object.nodeId}`;
      } else {
        url = `${url}&id=${object.nodeId}`;
      }
    });
    return new Promise((resolve, reject) => {
      const apiURL = url;
      this.httpClient
        .get<any[]>(apiURL)
        .toPromise()
        .then((res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  retrieveTimestampEpoch() {
    let url = "https://api-rest.wes.cl/wes/api/acl-entities/v1/time/epoch";
    return this.httpClient.get(url, {responseType: 'text'});
  }

  retrieveReport(nodes: any, from: string, to: string) {
    let url: string = this.host + `nodes/report/consumption`;
    nodes.forEach(function (object, index) {
      console.log(object);
      if(index == 0) {
        url = `${url}?nodeId=${object.nodeId}`;
      } else {
        url = `${url}&nodeId=${object.nodeId}`;
      }
    });
    url = `${url}&start=${from}&end=${to}`;
    window.location.href=url;
  }

  retrieveReportPdf(nodes: any, from: string, to: string) {
    let url: string = this.host + `nodes/report/consumption/pdf`;
    nodes.forEach(function (object, index) {
      console.log(object);
      if(index == 0) {
        url = `${url}?nodeId=${object.nodeId}`;
      } else {
        url = `${url}&nodeId=${object.nodeId}`;
      }
    });
    url = `${url}&start=${from}&end=${to}`;
    window.location.href=url;
  }
}

<div class="sidenav-container">
  <mat-toolbar color="accent" class="mat-elevation-z3">
    <mat-icon style="margin-right: 5px;">error_outline</mat-icon>
    <span>Auditorias de control</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="sidenavService.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-list>
    <div mat-subheader>Auditorias de control publicadas en el periodo seleccionado</div>
    <mat-list-item>
      <mat-icon mat-list-icon>swap_horizontal_circle_outline</mat-icon>
      <p mat-line>Lo valledor</p>
      <p mat-line style="color: darkgrey">Performance: 5.4 m<sup>3</sup></p>
      <p mat-line style="color: darkgrey">Consumo sin WES: 5.4 m<sup>3</sup>, 04/10 00:00 - 04/10 00:00</p>
      <p mat-line style="color: darkgrey">Consumo con WES: 5.4 m<sup>3</sup>, 04/10 00:00 - 04/10 00:00</p>
      <p mat-line style="color: #35b6f8;">Auditoria publicada hace 26 dias atras</p>
      <h5>Eficiencia <h3>56%</h3></h5>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</div>


import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import { WesLoginComponent } from './wes-login/wes-login.component';
import { WesLoginLayoutComponent } from './wes-login-layout/wes-login-layout.component';
import { WesSidenavComponent } from './wes-sidenav/wes-sidenav.component';
import { WesControlPanelComponent } from './wes-control-panel/wes-control-panel.component';
import { WesHomeLayoutComponent } from './wes-home-layout/wes-home-layout.component';
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { RouterModule } from "@angular/router";
import { WesNavigationBarComponent } from './wes-navigation-bar/wes-navigation-bar.component';
import { MatMenuModule} from "@angular/material/menu";
import { MatTooltipModule} from "@angular/material/tooltip";
import { MatFormFieldModule} from "@angular/material/form-field";
import { MatCardModule} from "@angular/material/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatInputModule} from "@angular/material/input";
import { MatProgressBarModule} from "@angular/material/progress-bar";
import { HttpClientModule } from '@angular/common/http';
import { WesDevicesComponent } from './wes-devices/wes-devices.component';
import { WesDevicesMobileComponent } from './wes-devices-mobile/wes-devices-mobile.component';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {MatChipsModule} from "@angular/material/chips";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatBadgeModule} from "@angular/material/badge";
import localeEsAr from '@angular/common/locales/es-AR';
import { FlexLayoutModule } from "@angular/flex-layout";
import {MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import {MatTabsModule} from "@angular/material/tabs";
import {MatExpansionModule} from "@angular/material/expansion";
import { WesSidenavDevicesComponent } from './wes-sidenav-devices/wes-sidenav-devices.component';
import {MatRippleModule} from '@angular/material/core';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSelectModule} from "@angular/material/select";
import { WesSidenavAlertsComponent } from './wes-sidenav-alerts/wes-sidenav-alerts.component';
import { WesSidenavSearchContentComponent } from './wes-sidenav-search-content/wes-sidenav-search-content.component';
import {MatStepperModule} from "@angular/material/stepper";
import {MatRadioModule} from "@angular/material/radio";
registerLocaleData(localeEsAr, 'es-AR');
import { CarouselModule } from 'ngx-owl-carousel-o';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { WesOnoffDialogComponent } from './wes-onoff-dialog/wes-onoff-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import { WesDevicesSheetComponent } from './wes-devices-sheet/wes-devices-sheet.component';
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import { WesAuditoriesComponent } from './wes-auditories/wes-auditories.component';
import { WesSidenavProfileComponent } from './wes-sidenav-profile/wes-sidenav-profile.component';
import { WesProfileComponent } from './wes-profile/wes-profile.component';
@NgModule({
  providers: [DatePipe, {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}, {provide: LOCALE_ID, useValue: 'es-AR'}],
  declarations: [WesLoginComponent, WesLoginLayoutComponent, WesSidenavComponent, WesControlPanelComponent, WesHomeLayoutComponent, WesNavigationBarComponent, WesDevicesComponent, WesDevicesMobileComponent, WesSidenavDevicesComponent, WesSidenavAlertsComponent, WesSidenavSearchContentComponent, WesOnoffDialogComponent, WesDevicesSheetComponent, WesAuditoriesComponent, WesSidenavProfileComponent, WesProfileComponent],
  imports: [
    HttpClientModule,
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    MatMenuModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressBarModule,
    NgxSkeletonLoaderModule,
    MatChipsModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatNativeDateModule,
    FlexLayoutModule,
    MatTabsModule,
    MatExpansionModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatStepperModule,
    MatRadioModule,
    FormsModule,
    CarouselModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatBottomSheetModule
  ]
})
export class ViewsModule { }

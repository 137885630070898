import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommandService {

  constructor(private httpClient: HttpClient) { }
  private host: string = "https://api-rest.wes.cl/wes/api/acl-command/v1/";

  retrieve_pending_command_onoff(nodeId: string): Observable<any[]> {
    let url: string = this.host + `node/${ nodeId }/commands/status/PENDING?command=ON&command=OFF`;
    return <Observable<any[]>> this.httpClient.get(url);
  }

  fetch_pending_onoff_commands(array: Array<any>): Observable<any[]> {
    let url: string = this.host + `node/commands?status=PENDING&command=ON&command=OFF`;
    array.forEach(function (object) {
        url = url + "&id=" + object.nodeId;
    });
    return <Observable<any[]>> this.httpClient.get(url);
  }

}

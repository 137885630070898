import {Component, OnInit, Input} from '@angular/core';
import {SidenavService} from '../../services/sidenav/sidenav.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {CommandService} from '../../services/command/command.service';
import {Subject, timer} from 'rxjs';
import {retry, share, switchMap, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {WesOnoffDialogComponent} from '../wes-onoff-dialog/wes-onoff-dialog.component';
import {NodeService} from '../../services/node/node.service';
import {UserService} from '../../services/user/user.service';

@Component({
  selector: 'wes-sidenav-devices',
  templateUrl: './wes-sidenav-devices.component.html',
  styleUrls: ['./wes-sidenav-devices.component.scss']
})
export class WesSidenavDevicesComponent implements OnInit {

  @Input('nodes') allowedNodes: Array<any>;
  @Input('connected') globalStatus: boolean;

  private stopPolling = new Subject();
  public user_data: any;
  public canOnOff: boolean = false;

  constructor(public sidenavService: SidenavService,
              public commandService: CommandService,
              public nodeService: NodeService,
              public userService: UserService,
              public dialog: MatDialog) { }

  ngOnInit(): void {

    this.canOnOff = false;
    const session = this.userService.retrieve_user_id();
    this.userService.fetch_session_information(session).then((data: any) => {
      this.canOnOff = data.switchEnabled;
    });
  }

  toggle(event: MatSlideToggleChange, node: any) {
    const dialogRef = this.dialog.open(WesOnoffDialogComponent, {
      data: {
        name: node.name,
        onoff: event.checked
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      node.waitingCommand = true;
      if (result) {
        node.pendingCommand = event.source.checked ? 'ON' : 'OFF';
        this.nodeService.sendOnOff(event.source.checked ? 'ON' : 'OFF', node.nodeId).subscribe(() => {
          node.waitingCommand = true;
        }, () => {
          node.waitingCommand = false;
          event.source.checked = !event.checked;
        });
      } else {
        node.waitingCommand = false;
        event.source.checked = !event.checked;
      }
    });
  }

  createPooling() {
      timer(0, 15000).pipe(
        switchMap(() => this.commandService.fetch_pending_onoff_commands(this.allowedNodes)),
        retry(),
        share(),
        takeUntil(this.stopPolling)
      ).subscribe(
        (commandData) => {
          this.nodeService.fetch_multiple_node_data(this.allowedNodes).subscribe((nodeData) => {
            nodeData.forEach(element => {
              let node = this.allowedNodes.filter((node) => { return node.nodeId == element.nodeId })[0];
              node.data.lastUpdate = element.lastUpdate;
              node.data.measureUpdate = element.measureUpdate;
              let nodeCommand = commandData.filter((commandNode) => { return commandNode.nodeId == node.nodeId });
              if (nodeCommand.length > 0) {
                node.waitingCommand = true;
                node.data.wesStatus = commandData[0].commandName;
                node.pendingCommand = commandData[0].commandName;
              } else {
                node.data.wesStatus = element.wesStatus;
                node.waitingCommand = false;
              }
            });
          });
        }
      );
  }

  close() {
    this.stopPolling.next();
    this.sidenavService.close();
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-wes-devices-sheet',
  templateUrl: './wes-devices-sheet.component.html',
  styleUrls: ['./wes-devices-sheet.component.scss']
})
export class WesDevicesSheetComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _bottomSheetRef: MatBottomSheetRef<WesDevicesSheetComponent>) { }

  ngOnInit(): void {

  }

  close() {
    this._bottomSheetRef.dismiss();
  }

}

import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import * as jwt_decode from 'jwt-decode';
import {UserService} from "../../services/user/user.service";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'wes-login',
  templateUrl: './wes-login.component.html',
  styleUrls: ['./wes-login.component.scss']
})
export class WesLoginComponent implements OnInit {

  public error: any = {
    change: {
      visible: false,
      message: ""
    },
    login: {
      visible: false,
      message: ""
    }
  }
  public change_password: boolean = false;
  public username = new FormControl({value: null, disabled: false}, [
    Validators.required
  ]);

  public password = new FormControl({value: null, disabled: false}, [
    Validators.required
  ]);

  public change_password_email = new FormControl({value: null, disabled: false}, [
    Validators.required
  ]);

  matcher = new MyErrorStateMatcher();
  public loading: boolean = false;

  constructor(private userService: UserService, private router: Router) {
  }

  ngOnInit(): void {

  }

  login() {
    if (this.username.invalid && this.password.invalid) {
      this.username.markAsTouched();
      this.password.markAsTouched();
      return
    } else if (this.username.invalid) {
      this.username.markAsTouched();
      return
    } else if (this.password.invalid) {
      this.password.markAsTouched();
      return
    }

    this.loading = true;

    this.userService.authenticate(this.username.value, this.password.value).subscribe(data=> {
      if (data) {
        localStorage.setItem("x-auth-token", data);
        let decode = jwt_decode(data);
        localStorage.setItem("user-id", decode.sub);
        this.loading = false;
        this.router.navigateByUrl("wes/control-panel");
      }
    }, error=> {
      this.error.login.message="User o password invalidos";
      this.error.login.visible=true;
      this.loading = false;
    });
  }

  forgotPassword() {
    if (this.change_password_email.invalid && this.change_password_email.invalid) {
      this.change_password_email.markAsTouched();
      this.change_password_email.markAsTouched();
      return
    }
    this.loading = true;
    this.userService.forgotPassword(this.change_password_email.value).subscribe(data=> {
      this.changeMode();
      this.loading = false;
    }, error=> {
      this.error.change.message="Usuario no registrado.";
      this.error.change.visible=true;
      this.loading = false;
    });
  }

  changeMode() {
    this.change_password = !this.change_password;
  }

}

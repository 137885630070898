<div class="sidenav-container">
  <mat-toolbar color="accent" class="mat-elevation-z3">
    <mat-icon style="margin-right: 5px;">nfc</mat-icon>
    <span>Dispositivos</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-list>
    <div mat-subheader *ngIf="!globalStatus" >Dispositivos sin conexión de Red o con señal inestable, hace mas de 2 horas. </div>
    <div mat-subheader *ngIf="globalStatus" >Dispositivos con conectividad de Red estable, por mas de 2 horas.</div>
    <mat-list-item *ngFor="let node of allowedNodes">
      <mat-icon *ngIf="!globalStatus" mat-list-icon>signal_cellular_no_sim</mat-icon>
      <mat-icon *ngIf="globalStatus" mat-list-icon>sim_card</mat-icon>
      <p mat-line><span class="statusIndicator active" [ngClass]="{'active': globalStatus}" matTooltip="{{node.nodeId}}" matTooltipPosition="before"></span>{{node.name}}</p>
      <small mat-line>Ultima conexión {{node.data.differenceConnectionText}}</small>
      <small mat-line>Actualizado {{node.data.differenceText}} <mat-icon style="color: darkorange" inline *ngIf="node.data.differenceUpdateHours > 1">warning</mat-icon> </small>
      <small mat-line *ngIf="node.waitingCommand" style="color: darkgray">Esta acción podria tomar algunos minutos</small>
      <mat-progress-bar mat-line *ngIf="node.waitingCommand" color="{{node.pendingCommand === 'ON' ? 'primary' : 'warn'}}" style="width: 220px;" bufferValue="80" mode="{{node.pendingCommand === 'ON' ? 'indeterminate' : 'query'}}"></mat-progress-bar>
      
      <mat-slide-toggle
        *ngIf="globalStatus"
        mat-line
        labelPosition="before"
        [checked]="node.data.wesStatus == 'ON'"
        [disabled]="node.waitingCommand || !canOnOff"
        (change)="toggle($event, node)"
      >
        Habilitación de consumo hídrico
      </mat-slide-toggle>
    </mat-list-item>
  </mat-list>
</div>




<div class="sidenav-container">
  <mat-toolbar color="accent" class="mat-elevation-z3">
    <mat-icon style="margin-right: 5px;">error_outline</mat-icon>
    <span>Alertas</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="sidenavService.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-list>
    <div mat-subheader>Eventos en red Hídrica sobre los puntos y fechas seleccionadas</div>
    <div *ngFor="let alert of nodeAlerts">
      <mat-list-item *ngFor="let measureAlert of alert.measureAlerts">
        <p mat-line>{{alert.name}}</p>
        <mat-icon mat-list-icon>{{measureAlert.change === 'EQ' ? 'swap_horizontal_circle_outline' : (measureAlert.change === 'UP' ? 'arrow_circle_up' : 'arrow_circle_down')}}</mat-icon>
        <p mat-line>Consumo hídrico prolongado, <b>{{measureAlert.measure * 1000 | number:'':'es'}}</b> <sup>Litros/Hr</sup></p>
        <p mat-line style="color: darkgrey">{{measureAlert.dateTime | date: 'EEEE, d MMMM y, HH:mm'}} hrs.</p>
        <h4 *ngIf="measureAlert.difference != '0'" [ngStyle]="{'color': measureAlert.difference > 0 ? 'red' : (measureAlert.difference < 0 ? 'green' : 'darkgrey') }">{{measureAlert.difference}}%</h4>
      </mat-list-item>
    </div>
  </mat-list>
</div>




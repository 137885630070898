
<mat-progress-bar *ngIf="change.loading" mode="indeterminate"></mat-progress-bar>
<div class="container">

  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign.gt-sm="center center">
    <div fxFlex.xl="50" fxFlex.lt-xl="66" style="flex: 1 1 auto; box-sizing: border-box;">

      <div fxLayoutGap="25px" fxLayoutGap.lt-xl="20px" fxLayoutGap.lt-md="32px" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">

        <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
          <h1>Perfil</h1>
          <p class="mat-body-1" style="color: darkgrey">Información de Usuario</p>
        </div>

        <div fxLayoutGap="10px" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
          <div fxFlex="10" style="flex: 1 1 auto; box-sizing: border-box;">
            <h4>Nombre</h4>
            <p class="mat-body-1" style="color: darkgrey">{{user_description}}</p>
          </div>
          <div fxFlex="10" style="flex: 1 1 auto; box-sizing: border-box;">
            <h4>Empresa</h4>
            <p class="mat-body-1" style="color: darkgrey">{{company_name}}</p>
          </div>
          <div fxFlex="30" style="flex: 1 1 auto; box-sizing: border-box;">
            <h4>User ID</h4>
            <p class="mat-body-1" style="color: darkgrey">{{session}}</p>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
          <h1>Contraseña</h1>
          <p class="mat-body-1" style="color: darkgrey">Modificación</p>
          <mat-form-field>
            <mat-label>Nueva contraseña</mat-label>
            <input matInput type="password" placeholder="****" [formControl]="password" [errorStateMatcher]="matcher">
            <mat-error *ngIf="password.hasError('required')">
              Campo <strong>requerido</strong>
            </mat-error>
            <mat-hint *ngIf="(response.visible && !response.error)" style="color: green">{{response.message}}</mat-hint>
            <mat-hint *ngIf="(response.visible && response.error)" style="color: red">{{response.message}}</mat-hint>
          </mat-form-field>
        </div>
        <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
          <button style="margin-right: 10px;" disabled="{{change.loading}}" mat-raised-button color="primary" (click)="changePassword(password.value)">Cambiar</button>
          <button disabled="{{change.loading}}" mat-raised-button (click)="volver()">Volver</button>
        </div>
      </div>

    </div>

  </div>


  <div fxLayoutGap="32px" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">

  </div>

</div>

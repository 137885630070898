import * as Highcharts from "highcharts";

export class Constants {

  public static base_chart: any = {
    chart: {
      zoomType: 'x',
      resetZoomButton: {
        position: {
          align: 'center', // right by default
          verticalAlign: 'top',
          x: 0,
          y: 0
        },
        relativeTo: 'chart'
      }
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
          ]
        },
        marker: {
          radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },
    title: {
      text: ' ',
      margin: 80,
      align: 'left',
    },
    series: [{
      type: 'area'
    }],
    legend: {
      enabled: false
    },
    credits: {
      enabled: false,
      text: "www.wes.cl",
      href: "https://www.wes.cl"
    },
    exporting: {
      enabled: false
    }

  };

  public static base_pie_chart: any = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        width: 480,
        height: 145,
        marginTop: 0
    },
    title: {
      text: ' ',
      margin: 80,
      align: 'left',
    },  
    tooltip: {
        pointFormat: '{series.name}: {point.y:,.1f} m3 ({point.x:.1f} % del total) <br>Gasto: ${point.z:,.0f}'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: (function () {
              var colors = [],
                  base = Highcharts.getOptions().colors[0],
                  i;
          
              for (i = 0; i < 7; i += 1) {
                  colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
              }
              return colors;
          }()),
            dataLabels: {
                enabled: true,
                format: '{point.name} | <b>{point.percentage:.1f}%</b>',
                distance: 10,
                filter: {
                    property: 'percentage',
                    operator: '>',
                    value: 4
                },
                color: '#004078'
            }
        }
    },
    series: [{
        name: 'Consumo',
        data: [
            { name: 'Lo valledor', y: 61.41, x: 3213, sliced: true, selected: true },
            { name: 'Estanque B', y: 11.84, x:222 },
            { name: 'Colina 1', y: 10.85, x:333 },
            { name: 'CPF San Miguel', y: 4.67, x:444 },
            { name: 'Copec costanera', y: 4.18, x:555 },
            { name: 'Copec lavado 1', y: 7.05, x: 666 }
        ]
    }],
    credits: {
      enabled: false,
      text: "www.wes.cl",
      href: "https://www.wes.cl"
    },
  };

  public static base_bar_chart: any = {
      chart: {
          type: 'bar'
      },
      title: {
          text: ''
      },
      subtitle: {
          text: ''
      },
      xAxis: {
          categories: [],
      },
      yAxis: {
        min: 0,
        title: {
            text: 'Porcentaje de consumo',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    },
      tooltip: {
        headerFormat: '',
        pointFormat: (
            '{point.name}<br/>' +
            'Consumo {point.options.custom.value:,.1f} m3 ({point.options.y:.1f} % del total) <br/> Gastos ${point.options.custom.expenses:,.0f} '
        )
     },
      plotOptions: {
        series: {
            keys: ['name', 'custom.value', 'y', 'custom.expenses'], // 4th data position as custom property
            stacking: 'normal',
            dataLabels: {
              enabled: true,
              inside: true,
              format: '{point.options.y:.1f} %'
            }
        }
      },
      credits: {
          enabled: false
      },
      series: [{
          showInLegend: false, 
          name: 'Porcentaje de consumo',
          data: []
      }]
  };

  public static DATEPICKER_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'DD-MM-YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY',
    },
  };

}

<div fxLayoutGap="32px" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
  <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
    <h1>Dispositivos</h1>
    <div *ngIf="devices">
      <p [hidden]="loading" class="mat-body-1" style="color: darkgrey">Wes ({{ devices.length }})</p>
    </div>

    <ngx-skeleton-loader
      [hidden]="!loading"
      count="1"
      appearance="circle"
      [theme]="{
                        width: '200px',
                        height: '10px',
                        'margin-left': '0px',
                        'margin-bottom': '0px',
                        'border-radius': '10px'
                      }">
    </ngx-skeleton-loader>
  </div>
  <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
    <ngx-skeleton-loader [hidden]="!loading"
                         count="3"
                         [theme]="{ 'border-radius': '10', height: '50px', 'margin-bottom': '0px'}"
    ></ngx-skeleton-loader>

    <mat-accordion class="example-headers-align" [hidden]="loading">
      <div *ngFor="let item of devices">
        <mat-expansion-panel *ngIf="item.data">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="statusIndicator" [ngClass]="{'active': (lastUpdate(item) < 2)}"></div>
              <small>{{item.name}}</small>
            </mat-panel-title>
            <mat-panel-description>
              <small><mat-icon style="font-size: 20px;">{{item.data.wesStatus == 'ON' ? 'verified' : 'highlight_off'}}</mat-icon></small>
              <small>Ultima actualización: {{ (lastUpdate(item) > 2) ? 'Hace ' + lastUpdate(item) + ' días' : item.data.lastUpdate | date: 'dd/MM/yyyy HH:mm'}}</small>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="panel-content">
            <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
              <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
                <table>
                  <tr>
                    <td><small><b>Estado de dispositivo:</b></small></td>
                    <td><div class="statusIndicator" [ngClass]="{'active': (lastUpdate(item) < 2)}"></div></td>
                  </tr>
                  <tr>
                    <td><small><b>Dispositivo:</b></small></td>
                    <td><small><mat-chip class="mat-chip-device">{{item.nodeId}}</mat-chip></small></td>
                  </tr>
                  <tr>
                    <td><small><b>Hora de dispositivo:</b></small></td>
                    <td><small>{{item.data.wesHour}}</small></td>
                  </tr>
                  <tr>
                    <td><small><b>Fecha de dispositivo:</b></small></td>
                    <td><small>{{item.data.wesDate | date: 'dd/MM/yyyy'}}</small></td>
                  </tr>
                </table>
              </div>
              <div fxFlex style="flex: 1 1 auto; box-sizing: border-box;">
                <table>
                  <tr>
                    <td><b><small>Ultima actualización de m3:</small></b> </td>
                    <td><small>{{item.data.measureUpdate | date: 'dd/MM/yyyy HH:mm'}}</small></td>
                  </tr>
                  <tr>
                    <td><small><b>Ultima conexión:</b></small> </td>
                    <td><small>{{item.data.lastUpdate | date: 'dd/MM/yyyy HH:mm'}}</small></td>
                  </tr>
                  <tr>
                    <td><small><b>Habilitación de consumo:</b></small></td>
                    <td><small><mat-chip class="mat-chip-device">{{item.data.wesStatus.toLowerCase()}}</mat-chip></small></td>
                  </tr>
                  <tr>
                    <td><small><b>Consumo ultima hora:</b></small></td>
                    <td><small>{{item.data.mch | number:'':'es'}} m3</small></td>
                  </tr>
                  <tr *ngIf="have_alert(item)">
                    <td><small><b>Perdida actual m3</b></small></td>
                    <td><small>{{ item.data.stream.split("@")[7] }} m3</small></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </mat-accordion>

  </div>
</div>


import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Observable} from "rxjs";
import {MatSidenav} from "@angular/material/sidenav";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map} from "rxjs/operators";
import {SidenavService} from "../../services/sidenav/sidenav.service";

@Component({
  selector: 'wes-sidenav',
  templateUrl: './wes-sidenav.component.html',
  styleUrls: ['./wes-sidenav.component.scss']
})
export class WesSidenavComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));

  @ViewChild('panel', { static: true }) private sidePanel: MatSidenav;
  @ViewChild('content', { static: true, read: ViewContainerRef }) private vcf: ViewContainerRef;

  constructor(private breakpointObserver: BreakpointObserver, private sidenavService: SidenavService) {}

  ngOnInit() {
    this.sidenavService.setPanel(this.sidePanel);
    this.sidenavService.setContentVcf(this.vcf);
  }
}
